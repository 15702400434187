<template>
  <div class="device_finger_wrap">
    <div class="device_finger_search">
      <el-button type="primary" class="devicefinger_btn" :loading="beingLoading" @click="getResponseHandle">Get</el-button>
    </div>
    <div class="device_finger_content">
      <el-collapse v-model="activeNames">
        <el-collapse-item v-for="(val, k, ind) in sucData" :key="ind" :title="k" :name="ind">
           <el-table
            :data="val"
            :row-class-name="getRowClassName"
            style="width: 100%">
            <el-table-column type="expand">
              <template slot-scope="props">
                <div class="child_box" v-if="props.row.hasObj">
                  <el-table
                    style="width: 100%"
                    :data="props.row.val">
                    <el-table-column
                      label="key"
                      prop="key">
                    </el-table-column>
                    <el-table-column
                      label="value"
                      prop="val">
                      <template slot-scope="scope">
                        <span>{{scope.row.val}}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div class="child_box" v-else-if="props.row.hasArr">
                  <div v-for="(item, index) in props.row.val" :key="index">
                    <h2>{{item.titlekey}}</h2>
                    <div v-if="item.grandObj">
                      <el-table
                        style="width: 100%"
                        :data="item.listVal">
                        <el-table-column
                          label="key"
                          prop="key">
                        </el-table-column>
                        <el-table-column
                          label="value"
                          prop="val">
                          <template slot-scope="scope">
                            <span>{{scope.row.val}}</span>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                    <div v-else>
                      <p>{{item.listVal}}</p>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="key"
              prop="key">
            </el-table-column>
            <el-table-column
              label="value"
              prop="val">
              <template slot-scope="scope">
                <span>{{(scope.row.hasObj || scope.row.hasArr) ? '' : scope.row.val}}</span>
              </template>
            </el-table-column>
           </el-table>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import {data} from './tool/device'
import './tool/raphael.min.js'
import { authDeviceFinger, upLoadToken, deviceInfo } from '@/api/modules/devicefinger'

export default {
  data() {
    return {
      responseData: data,
      sucData: {},
      activeNames: [0],
      dfpId: '',
      beingLoading: false
    };
  },
  mounted() {
    // this.showDetailsHandle()
  },
  methods: {
    dealStructure(agm) {
      let dataWarehouse
      try {
        dataWarehouse = JSON.parse(agm)
        return dataWarehouse;
      } catch (error) {
        return agm;
      } 
    },
    showDetailsHandle(agm) {
      // if (this.responseData.code === 'SUCCESS') {
        const data = agm || {}
        let showdata = {}
        if (Object.prototype.toString.call(data) === '[object Object]' && (Object.keys(data)).length > 0) {
          for (let i in data) {
            let tableArr = []
            if (Object.prototype.toString.call(data[i]) === '[object Object]' && (Object.keys(data[i])).length > 0) {
              const childObj = data[i]
              for (let k in childObj) {
                let childList = childObj[k]

                childObj[k] = (Object.prototype.toString.call(childObj[k]) === '[object String]') ? this.dealStructure(childObj[k]) : childObj[k]

                const childBtn = Object.prototype.toString.call(childObj[k]) === '[object Object]' && (Object.keys(childObj[k])).length > 0
                const childArrBtn = Object.prototype.toString.call(childObj[k]) === '[object Array]' && (Object.keys(childObj[k])).length > 0
                const grandsonData = childObj[k]
                if (childBtn) {
                  childList = []
                  for (let j in grandsonData) {
                    const grandsonList = {
                      key: j,
                      val: grandsonData[j],
                    }
                    childList.push(grandsonList)
                  }
                }
                if (childArrBtn) {
                  childList = []
                  for (let u = 0; u < grandsonData.length; u++) {
                    let grandList = grandsonData[u]

                    grandsonData[u] = (Object.prototype.toString.call(grandsonData[u]) === '[object String]') ? this.dealStructure(grandsonData[u]) : grandsonData[u]
                    const grandsonBtn = Object.prototype.toString.call(grandsonData[u]) === '[object Object]' && (Object.keys(grandsonData[u])).length > 0
                    let isTable = false
                    const grandsonSonData = grandsonData[u]
                    if (grandsonBtn) {
                      console.log(grandsonSonData)
                      grandList = []
                      isTable = true
                      for (let o in grandsonSonData) {
                        const grandsonSonList = {
                          key: o,
                          val: grandsonSonData[o],
                        }
                        grandList.push(grandsonSonList)
                      }
                    } else if (Object.prototype.toString.call(grandsonSonData) === '[object String]' || Object.prototype.toString.call(grandsonSonData) === '[object Number]' || Object.prototype.toString.call(grandsonSonData) === '[object Boolean]' || Object.prototype.toString.call(grandsonSonData) === '[object Null]' || Object.prototype.toString.call(grandsonSonData) === '[object Array]') {
                      grandList = []
                      isTable = true
                      const grandsonSonList = {
                        key: `childValue`,
                        val: grandsonSonData,
                      }
                      grandList.push(grandsonSonList)
                    }
                    const grandsonList = {
                      titlekey: `No.${u+1}`,
                      listVal: grandList,
                      grandObj: isTable
                    }
                    childList.push(grandsonList)
                  }
                }
                childList = (childList === '' || childList === null) ? '-' : childList
                const obj = {
                  key: k,
                  hasObj: childBtn,
                  hasArr: childArrBtn,
                  val: childList,
                }
                tableArr.push(obj)
              }
            } else {
              tableArr = [{
                key: i,
                val: data[i],
              }]
            }
            showdata[i] = tableArr
          }
          this.sucData = showdata
        }
      // }
    },
    getRowClassName({row}) {
      if (!(row.hasObj) && !(row.hasArr)) {
        return 'row-expand-cover'
      }
    },
    async _getPaphaelDemand() {
       try{
        const { data } = await authDeviceFinger()
        if (data.code !== 'SUCCESS') {
          this.$message({message: data.message, type: 'error', customClass: 'message_box'})
        } else {
          const fun = window.raphael()
          const that = this
          // const flag_key = fun.setAccessKey('ai.advance.liveness.demo-QkFhFaZ') // set access key
          // const flag_env = fun.setEnv('advance-demo.gw-dv.net') // configure device fingerprint server endpoint
          const flag_key = fun.setAccessKey(data.data.accessKey) // set access key'ai.advance.liveness.demo-QkFhFaZ'
          const flag_env = fun.setEnv(data.data.customDomain) // configure device fingerprint server endpoint advance-demo.gw-dv.net
          if(flag_key == 'success' && flag_env == 'success') {
            fun.initToken(res => {
              console.log('dvtoken', res); // get DV token from the callback
              that._getUpLoadToken(res)
            })
          }
        }
      } catch (e) {
        this.$message({message: e.message, type: 'error', customClass: 'message_box'})
      } finally {
        this.beingLoading = false
      }
    },
    async _getUpLoadToken(res) {
      const resStr = res || ''
      try{
        const { data } = await upLoadToken({token: resStr})
        if (data.code !== 'SUCCESS') {
          this.$message({message: data.message, type: 'error', customClass: 'message_box'})
        } else {
          const { data: datainfo } = await deviceInfo({dfpId: data.data.dfpId})
          if (datainfo.code !== 'SUCCESS') {
            this.$message({message: datainfo.message, type: 'error', customClass: 'message_box'})
          } else {
            this.showDetailsHandle(datainfo.data)
          }
        }
      } catch (e) {
        this.$message({message: e.message, type: 'error', customClass: 'message_box'})
      } finally {
        this.beingLoading = false
      }
    },
    getResponseHandle() {
      this.beingLoading = true
      this._getPaphaelDemand()
    },
  }
}
</script>

<style lang="scss">
.device_finger_wrap{
  .row-expand-cover{
    .el-table__expand-icon{
      visibility:hidden;
    }
  }
  .el-table{
    .el-table__expand-column{
      .cell{
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .device_finger_content{
    .el-collapse-item__header{
      font-size: 14px;
      font-weight: bold;
    }
  }
  .child_box{
    border: 1px solid #f4f4f4;
    margin: 20px 0 30px 0;
  }
}
</style>

<style lang="scss" scoped>
  .device_finger_wrap{
    padding: 15px;
    box-sizing: border-box;
    .device_finger_search{
      text-align: center;
    }
    .devicefinger_btn{
      width: 200px;
      margin-bottom: 40px;
    }
  }
</style>
