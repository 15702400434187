const data ={
	"raphael_data_get": {
		"j42": "2",
		"j43": "1032",
		"j44": "1728",
		"j45": "38",
		"j46": "0",
		"j17": "{\"hasFlash\":0,\"version\":0}",
		"j50": "{\"charging\":false,\"level\":0.5,\"chargingTime\":null,\"dischargingTime\":25200}",
		"j51": "false",
		"d15": "web",
		"j52": "not_supported",
		"j53": "[0,{\"name\":\"PDF Viewer\"},false]",
		"j54": "false",
		"n6": "HK",
		"j55": "false",
		"j3": "http:\/\/localhost:8080\/devicefinger\/h5\/index",
		"j57": "false",
		"j58": "false",
		"j59": "false",
		"j73": "3456*2234",
		"j7": "30",
		"j18": "true",
		"j8": "true",
		"j1": "{\"name\":\"Netscape\",\"version\":\"5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/100.0.4896.75 Safari\/537.36\",\"code\":\"Mozilla\",\"Agent\":\"Mozilla\/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit\/537.36 (KHTML, like Gecko) Chrome\/100.0.4896.75 Safari\/537.36\"}",
		"j62": "c3f1f7c1-d9eb-4a51-a19a-d2af679f1d9f",
		"j60": "false",
		"j10": "zh-CN",
		"j5": "1728",
		"j64": "Mac",
		"j4": "http:\/\/localhost:8080\/devicefinger\/h5\/index",
		"j12": "macintel",
		"v1": "JD_9UDz-NdIYhEu3lBbiVBxEeGCBJ9gySBsTyJ_1c0y6PV",
		"j56": "false",
		"j2": "[{\"name\":\"PDF Viewer\",\"filename\":\"internal-pdf-viewer\",\"description\":\"Portable Document Format\",\"version\":\"\"},{\"name\":\"Chrome PDF Viewer\",\"filename\":\"internal-pdf-viewer\",\"description\":\"Portable Document Format\",\"version\":\"\"},{\"name\":\"Chromium PDF Viewer\",\"filename\":\"internal-pdf-viewer\",\"description\":\"Portable Document Format\",\"version\":\"\"},{\"name\":\"Microsoft Edge PDF Viewer\",\"filename\":\"internal-pdf-viewer\",\"description\":\"Portable Document Format\",\"version\":\"\"},{\"name\":\"WebKit built-in PDF\",\"filename\":\"internal-pdf-viewer\",\"description\":\"Portable Document Format\",\"version\":\"\"}]",
		"j15": "-8",
		"j11": "[\"zh-CN\",\"zh\"]",
		"j36": "true",
		"j16": "1649654631770",
		"j37": "true",
		"j6": "1117",
		"j38": "true",
		"v2": "6.2",
		"j39": "10",
		"n5": "45.251.107.111",
		"j40": "not_supported",
		"v3": "JT_xCamjGufHUPxw0ozzAt4woo_G2OAib1O12-Hg0YBE4-",
		"j41": "8"
	},
	"err_code": 0
}

export { data }
