import { render, staticRenderFns } from "./indexNextData.vue?vue&type=template&id=fff2ed02&scoped=true&"
import script from "./indexNextData.vue?vue&type=script&lang=js&"
export * from "./indexNextData.vue?vue&type=script&lang=js&"
import style0 from "./indexNextData.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./indexNextData.vue?vue&type=style&index=1&id=fff2ed02&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fff2ed02",
  null
  
)

export default component.exports